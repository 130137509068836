import axios from 'axios'
import store from '@/store'

const service = axios.create({
  baseURL: process.env.VUE_APP_AUTHORIZED_BASE_API,
  timeout: 50000
})

service.interceptors.request.use(
  request => {
    if (store.getters.accessToken) {
      request.headers['Authorization'] = 'Bearer ' + store.getters.accessToken
    }
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
