import authorizedRequest from '@/utils/authorizedRequest'
import unauthorizedRequest from '@/utils/unauthorizedRequest'
import wpApiRequest from "@/utils/wpApiRequest"
import logApiRequest from "@/utils/logApiRequest"
import cmApiRequest from "@/utils/cmApiRequest"

// ----------------------
// - 要認証系API
// ----------------------
export const createUser = (data) => {
  return authorizedRequest({
    url: '2023-04/user?campaign_flag=false',
    method: 'post',
    data
  })
}

export const getUser = (userId) => {
  return authorizedRequest({
    url: `2023-04/user/${userId}`,
    method: 'get',
  })
}

export const putUser = (data, userId) => {
  return authorizedRequest({
    url: `2023-04/user/${userId}`,
    method: 'put',
    data
  })
}

export const shopifyMultipassToken = (data) => {
  const {user_id} = data
  return authorizedRequest({
    url: `/shopify/${user_id}/multipass-token`,
    method: 'post',
    data
  })
}

export const getSecretPhrase = () => {
  return authorizedRequest({
    url: `secret-phrase`,
    method: 'get',
  })
}

export const getPoint = (data) => {
  const {user_id} = data
  return authorizedRequest({
    url: `point/${user_id}`,
    method: 'get',
  })
}

export const postSecretPhrase = (data) => {
  const {user_id, secret_phrases} = data
  return authorizedRequest({
    url: `/user/${user_id}/secret-phrase/verify`,
    method: 'post',
    data: secret_phrases
  })
}

export const postPointMigration = (data) => {
  const {user_id} = data
  return authorizedRequest({
    url: `/point/${user_id}/migration`,
    method: 'post',
    data: {target_user_id: data.target_user_id}
  })
}

export const postComparePoint = (data) => {
  const {user_id} = data
  return authorizedRequest({
    url: `/user/${user_id}/compare-point`,
    method: 'post',
    data: {source_user_token: data.source_user_token}
  })
}

export const resetPasswordWithPhrase = (data) => {
  return authorizedRequest({
    url: '/user/reset-password-with-phrase',
    method: 'post',
    data
  })
}

// ----------------------
// - 非認証系API
// ----------------------
export const getMigrationInfo = (data) => {
  return unauthorizedRequest({
    url: '/user/migration_info',
    method: 'post',
    data
  })
}

export const updateMigrationInfo = (data) => {
  return unauthorizedRequest({
    url: '/user/migration_info',
    method: 'put',
    data
  })
}

// ----------------------
// - ブランドサイトAPI
// ----------------------

export const getIsEntry = (params) => {
  return wpApiRequest({
    url: '/api-year-cup-is-entry',
    method: 'get',
    params: params
  })
}

export const getStores = (params) => {
  return wpApiRequest({
    url: '/api-year-cup-stores',
    method: 'get',
    params: params
  })
}

export const getPeriods = (params) => {
  return wpApiRequest({
    url: '/api-year-cup-periods',
    method: 'get',
    params: params
  })
}

export const getCups = (params) => {
  return wpApiRequest({
    url: '/api-year-cup-cups',
    method: 'get',
    params: params
  })
}

export const postYearCupEntry = (data) => {
  return wpApiRequest({
    url: '/api-year-cup-entry',
    method: 'post',
    data
  })
}

export const putYearCupEdit = (data) => {
  return wpApiRequest({
    url: '/api-year-cup-edit',
    method: 'put',
    data
  })
}

// ----------------------
// - ログAPI
// ----------------------

export const postLog = (data) => {
  return logApiRequest({
    url: '/prod',
    method: 'post',
    data
  })
}

// ----------------------
// - cm_api
// ----------------------

export const getGiftItemsCustomer = (params) => {
  return cmApiRequest({
    url: '/api/sst/members/gift_items_customer',
    method: 'get',
    params: params
  })
}

export const getCouponVerify = (params) => {
  return cmApiRequest({
    url: '/api/sst/coupon_verify',
    method: 'get',
    params: params
  })
}

export const getSecretQuestions = (params) => {
  return cmApiRequest({
    url: '/api/sst/secret_questions',
    method: 'get',
    params: params
  })
}

export const getInterests = (params) => {
  return cmApiRequest({
    url: '/api/interests',
    method: 'get',
    params: params
  })
}

export const getTermsLatest = () => {
  return cmApiRequest({
    url: '/api/terms/latest',
    method: 'get'
  })
}

export const postRegistrations = (data) => {
  return cmApiRequest({
    url: '/api/auth/sst/registrations',
    method: 'post',
    data
  })
}

export const postConfirmationCode = (data) => {
  return cmApiRequest({
    url: '/api/auth/confirmation/code',
    method: 'post',
    data
  })
}

export const postSessions = (data) => {
  return cmApiRequest({
    url: '/api/auth/sst/sessions',
    method: 'post',
    data
  })
}

export const postPassword = (data) => {
  return cmApiRequest({
    url: '/api/auth/password',
    method: 'post',
    data
  })
}

export const putPassword = (data) => {
  return cmApiRequest({
    url: '/api/auth/password',
    method: 'put',
    data
  })
}

export const postPasswordResetBySecretPhrase = (data) => {
  return cmApiRequest({
    url: '/api/auth/password/reset_by_secret_phrase',
    method: 'post',
    data
  })
}

export const postIntegrationLogin = (data) => {
  return cmApiRequest({
    url: '/api/sst/integration/login',
    method: 'post',
    data
  })
}

export const postIntegrationQuestion = (data) => {
  return cmApiRequest({
    url: '/api/sst/integration/question',
    method: 'post',
    data
  })
}

export const postIntegration = (data) => {
  return cmApiRequest({
    url: '/api/sst/integration',
    method: 'post',
    data
  })
}
