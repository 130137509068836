import axios from "axios"

const service = axios.create({
  baseURL: process.env.VUE_APP_CM_API_BASE_URL,
  timeout: 50000,
  headers: {
    'From-Web': 'true'
  }
})

export default service
