import {createStore} from 'vuex'
import cognito from '@/utils/cognito'
import { getInterests, getSecretQuestions, postPassword, postPasswordResetBySecretPhrase, postSessions, putPassword, getTermsLatest, postRegistrations, postConfirmationCode, postIntegrationLogin, postIntegration, postIntegrationQuestion } from '@/utils/api'
import dayjs from 'dayjs'

const store = createStore({
  state: {
    errorMessages: '',
    isShowModal: false,
    email: '',
    emailUserName: '',
    emailDomainName: '',
    otherEmailDomain: '',
    password: '',
    passwordConfirm: '',
    birth: {
      year: 1990,
      month: 1,
      day: 1,
    },
    firstName: '',
    lastName: '',
    firstNameKana: '',
    lastNameKana: '',
    accessToken: '',
    loginId: '',
    userId: '',
    hasPhoneNumber: false,
    unnecessaryAccessToken: '',
    unnecessaryEmail: '',
    unnecessaryPoint: '',
    unnecessaryAnswer: '',
    unnecessaryQuestion: '',
    integrationMailError: '',
    sourceUser: null,
    targetUser: null,
    isPasswordCertification: false,
    gender: '',
    occupation: '',
    isMailSubscribed: false,
    isShopifyMailSubscribed: false,
    interests: [],
    question1: null,
    question2: null,
    answer1: '',
    answer2: '',
    agreedToTermsOfUse: true,
    questionsData: [],
    interestsData: [],
    termId: null,
    yearCupCount: {
      typeAId: "",
      typeASelect: 0,
      typeBId: "",
      typeBSelect: 0,
      isPickUp: false
    },
    yearCupAddress: {
      email: '',
      name: '',
      kana: '',
      zipcode: '',
      prefectures: '',
      city: '',
      address1: '',
      address2: '',
      tel: '',
    },
    yearCupPickup: {
      email: '',
      name: '',
      kana: '',
      tel: '',
      shop: {
        code: '',
        name: ''
      }
    },
    yearCupEntryId: '',
    yearCupPeriodId: '',
    yearCupCompleteId: '',
    redirectTo: null,
  },
  getters: {
    errorMessages: state => state.errorMessages,
    firstName: state => state.firstName,
    lastName: state => state.lastName,
    firstNameKana: state => state.firstNameKana,
    lastNameKana: state => state.lastNameKana,
    email: state => state.email,
    emailUserName: state => state.emailUserName,
    emailDomainName: state => state.emailDomainName,
    otherEmailDomain: state => state.otherEmailDomain,
    gender: state => state.gender,
    occupation: state => state.occupation,
    isMailSubscribed: state => state.isMailSubscribed,
    isShopifyMailSubscribed: state => state.isShopifyMailSubscribed,
    interests: state => state.interests,
    password: state => state.password,
    birth: state => state.birth,
    isShowModal: state => state.isShowModal,
    accessToken: state => state.accessToken,
    loginId: state => state.loginId,
    userId: state => state.userId,
    hasPhoneNumber: state => state.hasPhoneNumber,
    unnecessaryAccessToken: state => state.unnecessaryAccessToken,
    unnecessaryEmail: state => state.unnecessaryEmail,
    unnecessaryPoint: state => state.unnecessaryPoint,
    unnecessaryQuestion: state => state.unnecessaryQuestion,
    unnecessaryAnswer: state => state.unnecessaryAnswer,
    integrationTargetAccessToken: state => state.integrationTargetAccessToken,
    integrationTargetEmail: state => state.integrationTargetEmail,
    integrationTargetPoint: state => state.integrationTargetPoint,
    integrationMailError: state => state.integrationMailError,
    sourceUser: state => state.sourceUser,
    targetUser: state => state.targetUser,
    isPasswordCertification: state => state.isPasswordCertification,
    question1: state => state.question1,
    question2: state => state.question2,
    answer1: state => state.answer1,
    answer2: state => state.answer2,
    agreedToTermsOfUse: state => state.agreedToTermsOfUse,
    questionsData: state => state.questionsData,
    interestsData: state => state.interestsData,
    termId: state => state.termId,
    yearCupCount: state => state.yearCupCount,
    yearCupAddress: state => state.yearCupAddress,
    yearCupPickup: state => state.yearCupPickup,
    yearCupEntryId: state => state.yearCupEntryId,
    yearCupPeriodId: state => state.yearCupPeriodId,
    yearCupCompleteId: state => state.yearCupCompleteId,
    redirectTo: state => state.redirectTo,
  },
  mutations: {
    SET_ERROR_MESSAGES: (state, errorMessages) => {
      state.errorMessages = errorMessages
    },
    SET_FIRST_NAME: (state, firstName) => {
      state.firstName = firstName
    },
    SET_LAST_NAME: (state, lastName) => {
      state.lastName = lastName
    },
    SET_FIRST_NAME_KANA: (state, firstNameKana) => {
      state.firstNameKana = firstNameKana
    },
    SET_LAST_NAME_KANA: (state, lastNameKana) => {
      state.lastNameKana = lastNameKana
    },
    SET_GENDER: (state, gender) => {
      state.gender = gender
    },
    SET_OCCUPATION: (state, occupation) => {
      state.occupation = occupation
    },
    SET_IS_MAIL_SUBSCRIBED: (state, isMailSubscribed) => {
      state.isMailSubscribed = isMailSubscribed
    },
    SET_IS_SHOPIFY_MAIL_SUBSCRIBED: (state, isShopifyMailSubscribed) => {
      state.isShopifyMailSubscribed = isShopifyMailSubscribed
    },
    SET_INTERESTS: (state, interests) => {
      state.interests = interests
    },
    SET_EMAIL: (state, email) => {
      state.email = email
    },
    SET_EMAIL_USER: (state, emailUserName) => {
      state.emailUserName = emailUserName
    },
    SET_EMAIL_DOMAIN: (state, emailDomainName) => {
      state.emailDomainName = emailDomainName
    },
    SET_OTHER_EMAIL_DOMAIN: (state, otherEmailDomain) => {
      state.otherEmailDomain = otherEmailDomain
    },
    SET_PASSWORD: (state, password) => {
      state.password = password
    },
    SET_PASSWORD_CONFIRM: (state, passwordConfirm) => {
      state.passwordConfirm = passwordConfirm
    },
    SET_BIRTH: (state, birth) => {
      state.birth = birth
    },
    SET_QUESTION1: (state, question1) => {
      state.question1 = question1
    },
    SET_QUESTION2: (state, question2) => {
      state.question2 = question2
    },
    SET_ANSWER1: (state, answer1) => {
      state.answer1 = answer1
    },
    SET_ANSWER2: (state, answer2) => {
      state.answer2 = answer2
    },
    SET_AGREED_TO_TERMS_OF_USE: (state, agreedToTermsOfUse) => {
      state.agreedToTermsOfUse = agreedToTermsOfUse
    },
    SET_QUESTIONS_DATA: (state, questionsData) => {
      state.questionsData = questionsData
    },
    SET_INTERESTS_DATA: (state, interestsData) => {
      state.interestsData = interestsData
    },
    SET_TERM_ID: (state, termId) => {
      state.termId = termId
    },
    SET_IS_SHOW_MODAL: (state, isShowModal) => {
      state.isShowModal = isShowModal
    },
    SET_ACCESS_TOKEN: (state, accessToken) => {
      state.accessToken = accessToken
    },
    SET_UNNECESSARY_ACCESS_TOKEN: (state, accessToken) => {
      state.unnecessaryAccessToken = accessToken
    },
    SET_UNNECESSARY_EMAIL: (state, email) => {
      state.unnecessaryEmail = email
    },
    SET_UNNECESSARY_POINT: (state, point) => {
      state.unnecessaryPoint = point
    },
    SET_UNNECESSARY_QUESTION: (state, question) => {
      state.unnecessaryQuestion = question
    },
    SET_UNNECESSARY_ANSWER: (state, answer) => {
      state.unnecessaryAnswer = answer
    },
    SET_INTEGRATION_TARGET_ACCESS_TOKEN: (state, accessToken) => {
      state.integrationTargetAccessToken = accessToken
    },
    SET_INTEGRATION_TARGET_EMAIL: (state, email) => {
      state.integrationTargetEmail = email
    },
    SET_INTEGRATION_TARGET_POINT: (state, point) => {
      state.integrationTargetPoint = point
    },
    SET_INTEGRATION_MAIL_ERROR: (state, integrationMailError) => {
      state.integrationMailError = integrationMailError
    },
    SET_IS_PASSWORD_CERTIFICATION: (state, isPasswordCertification) => {
      state.isPasswordCertification = isPasswordCertification
    },
    SET_LOGIN_ID: (state, loginId) => {
      state.loginId = loginId
    },
    SET_USER_ID: (state, userId) => {
      state.userId = userId
    },
    SET_SOURCE_USER: (state, sourceUser) => {
      state.sourceUser = sourceUser
    },
    SET_TARGET_USER: (state, targetUser) => {
      state.targetUser = targetUser
    },
    SET_HAS_PHONE_NUMBER: (state, hasPhoneNumber) => {
      state.hasPhoneNumber = hasPhoneNumber
    },
    SET_YEAR_CUP_COUNT: (state, yearCupCount) => {
      state.yearCupCount = yearCupCount
    },
    SET_YEAR_CUP_ADDRESS: (state, yearCupAddress) => {
      state.yearCupAddress = yearCupAddress
    },
    SET_YEAR_CUP_PICKUP: (state, yearCupPickup) => {
      state.yearCupPickup = yearCupPickup
    },
    SET_YEAR_CUP_ENTRY_ID: (state, yearCupEntryId) => {
      state.yearCupEntryId = yearCupEntryId
    },
    SET_YEAR_CUP_PERIOD_ID: (state, yearCupPeriodId) => {
      state.yearCupPeriodId = yearCupPeriodId
    },
    SET_YEAR_CUP_COMPLETE_ID: (state, yearCupCompleteId) => {
      state.yearCupCompleteId = yearCupCompleteId
    },
    SET_REDIRECT_TO: (state, redirectTo) => {
      state.redirectTo = redirectTo
    }
  },
  actions: {
    setErrorMessages({ commit }, errorMessages) {
      commit('SET_ERROR_MESSAGES', errorMessages)
    },
    openModal({commit}) {
      commit('SET_IS_SHOW_MODAL', true)
    },
    closeModal({commit}) {
      commit('SET_IS_SHOW_MODAL', false)
    },
    login({commit}, {email, password}) {
      commit('SET_EMAIL', email)
      commit('SET_PASSWORD', password)
      return postSessions({email, password})
    },
    fetchQuestionsData({commit}, { app_scopes }) {
      return getSecretQuestions({ app_scopes })
        .then(result => {
          commit('SET_QUESTIONS_DATA', result.data.app_secret_questions)
        }).catch(err => console.error(err))
    },
    fetchInterestsData({commit}) {
      return getInterests({})
        .then(result => {
          commit('SET_INTERESTS_DATA', result.data)
        }).catch(err => console.error(err))
    },
    fetchTermsLatest({commit}) {
      return getTermsLatest()
        .then(result => {
          commit('SET_TERM_ID', result.data.id)
        }).catch(err => console.error(err))
    },
    signUpComfirm({commit}, {
      firstName,
      lastName,
      firstNameKana,
      lastNameKana,
      gender,
      occupation,
      isMailSubscribed,
      isShopifyMailSubscribed,
      interests,
      email,
      emailUserName,
      emailDomainName,
      otherEmailDomain,
      password,
      birth,
      question1,
      question2,
      answer1,
      answer2,
      agreedToTermsOfUse
    }) {
      return new Promise((resolve, reject) => {
        commit('SET_FIRST_NAME', firstName)
        commit('SET_LAST_NAME', lastName)
        commit('SET_FIRST_NAME_KANA', firstNameKana)
        commit('SET_LAST_NAME_KANA', lastNameKana)
        commit('SET_EMAIL', email)
        commit('SET_EMAIL_USER', emailUserName)
        commit('SET_EMAIL_DOMAIN', emailDomainName)
        commit('SET_OTHER_EMAIL_DOMAIN', otherEmailDomain)
        commit('SET_PASSWORD', password)
        commit('SET_BIRTH', birth)
        commit('SET_GENDER', gender)
        commit('SET_OCCUPATION', occupation)
        commit('SET_IS_MAIL_SUBSCRIBED', isMailSubscribed)
        commit('SET_IS_SHOPIFY_MAIL_SUBSCRIBED', isShopifyMailSubscribed)
        commit('SET_INTERESTS', interests)
        commit('SET_QUESTION1', question1)
        commit('SET_QUESTION2', question2)
        commit('SET_ANSWER1', answer1)
        commit('SET_ANSWER2', answer2)
        commit('SET_AGREED_TO_TERMS_OF_USE', agreedToTermsOfUse)
        resolve()
      })
    },
    signUp({ state }) {
      return postRegistrations({
        email: state.email,
        password: state.password,
        first_name: state.firstName,
        last_name: state.lastName,
        first_name_kana: state.firstNameKana,
        last_name_kana: state.lastNameKana,
        birthday: dayjs(new Date(state.birth.year, state.birth.month - 1, state.birth.day)).format('YYYY-MM-DD'),
        gender: state.gender,
        occupation: state.occupation,
        is_mail_subscribed: state.isMailSubscribed,
        is_shopify_mail_subscribed: state.isShopifyMailSubscribed,
        interest_ids: state.interests,
        secret_questions: [
          { app_secret_question_id: state.question1, secret_phrase: state.answer1 },
          { app_secret_question_id: state.question2, secret_phrase: state.answer2 },
        ],
        is_commercial: state.agreedToTermsOfUse,
        term_id: state.termId,
      })
    },
    confirmRegistration(_, {email, code}) {
      return postConfirmationCode({ email, confirmation_code: code })
    },
    forgetPassword({commit}, email) {
      commit('SET_EMAIL', email)
      return postPassword({ email })
    },
    confirmPassword(_, {email, reset_password_code, password, password_confirmation}) {
      return putPassword({ email, reset_password_code, password, password_confirmation })
    },
    resetPasswordWithPhrase({ commit }, { email, app_secret_question_id, secret_phrase }) {
      commit('SET_EMAIL', email)
      return postPasswordResetBySecretPhrase({ email, app_secret_question_id, secret_phrase })
    },
    resendConfirmationCode({commit}, email) {
      commit('SET_EMAIL', email)
      return cognito.resendConfirmationCode(email)
    },
    updateAttributes(_, attributes) {
      return cognito.updateAttributes(attributes)
    },
    confirmUpdate(_, {hasPhoneNumber, code}) {
      return cognito.confirmUpdate(hasPhoneNumber, code)
    },
    setHasPhoneNumber({commit}, {hasPhoneNumber}) {
      return new Promise((resolve, reject) => {
        commit('SET_HAS_PHONE_NUMBER', hasPhoneNumber)
        resolve()
      })
    },
    setPointIntegrationUser({commit}, {sourceUser, targetUser}) {
      return new Promise((resolve, reject) => {
        commit('SET_SOURCE_USER', sourceUser)
        commit('SET_TARGET_USER', targetUser)
        resolve()
      })
    },
    setIsPasswordCertification({commit}, {isPasswordCertification}) {
      return new Promise((resolve, reject) => {
        commit('SET_IS_PASSWORD_CERTIFICATION', isPasswordCertification)
        resolve()
      })
    },
    loginToForm({commit}, {loginId}) {
      return new Promise((resolve, reject) => {
        commit('SET_LOGIN_ID', loginId)
        resolve()
      })
    },
    signInToForm({commit}, {userId}) {
      return new Promise((resolve, reject) => {
        commit('SET_USER_ID', userId)
        resolve()
      })
    },
    integrationQuestionAnswer({commit}, {email, question, answer}) {
      return new Promise((resolve, reject) => {
        commit('SET_INTEGRATION_MAIL_ERROR', '')
        commit('SET_UNNECESSARY_EMAIL', email)
        commit('SET_UNNECESSARY_QUESTION', question)
        commit('SET_UNNECESSARY_ANSWER', answer)
        resolve()
      })
    },
    integrationMailError({commit}, {error}) {
      return new Promise((resolve, reject) => {
        commit('SET_INTEGRATION_MAIL_ERROR', error)
        resolve()
      })
    },
    integrationLoginSource({commit}, {email, password}) {
      return postIntegrationLogin({ email, password })
        .then(res => {
          commit('SET_UNNECESSARY_ACCESS_TOKEN', res.data.token)
          commit('SET_UNNECESSARY_POINT', res.data.point)
          commit('SET_UNNECESSARY_EMAIL', email)
        })
    },
    integrationLoginTarget({commit}, {email, password}) {
      return postIntegrationLogin({ email, password })
        .then(res => {
          commit('SET_INTEGRATION_TARGET_ACCESS_TOKEN', res.data.token)
          commit('SET_INTEGRATION_TARGET_POINT', res.data.point)
          commit('SET_INTEGRATION_TARGET_EMAIL', email)
        })
    },
    integrationQuestionSource({commit}, {email, app_secret_question_id, secret_phrase}) {
      return postIntegrationQuestion({ email, app_secret_question_id, secret_phrase })
        .then(res => {
          commit('SET_UNNECESSARY_ACCESS_TOKEN', res.data.token)
          commit('SET_UNNECESSARY_POINT', res.data.point)
          commit('SET_UNNECESSARY_EMAIL', email)
        })
    },
    postIntegration({ state }) {
      return postIntegration({
        source_email: state.unnecessaryEmail,
        source_token: state.unnecessaryAccessToken,
        target_email: state.integrationTargetEmail,
        target_token: state.integrationTargetAccessToken,
      })
    },
    setAttribute({commit}, {
      firstName,
      lastName,
      firstNameKana,
      lastNameKana,
      gender,
      occupation,
      interests,
      email,
      emailUserName,
      emailDomainName,
      otherEmailDomain,
      birth,
    }) {
      return new Promise((resolve, reject) => {
        commit('SET_FIRST_NAME', firstName)
        commit('SET_LAST_NAME', lastName)
        commit('SET_FIRST_NAME_KANA', firstNameKana)
        commit('SET_LAST_NAME_KANA', lastNameKana)
        commit('SET_EMAIL', email)
        commit('SET_EMAIL_USER', emailUserName)
        commit('SET_EMAIL_DOMAIN', emailDomainName)
        commit('SET_OTHER_EMAIL_DOMAIN', otherEmailDomain)
        commit('SET_BIRTH', birth)
        commit('SET_GENDER', gender)
        commit('SET_OCCUPATION', occupation)
        commit('SET_INTERESTS', interests)
        resolve()
      })
    },
    setCupSelect({commit}, {yearCupCount}) {
      return new Promise((resolve, reject) => {
        commit('SET_YEAR_CUP_COUNT', yearCupCount)
        resolve()
      })
    },
    setYearCupAddress({commit}, {yearCupAddress}) {
      return new Promise((resolve, reject) => {
        commit('SET_YEAR_CUP_ADDRESS', yearCupAddress)
        resolve()
      })
    },
    setYearCupPickUp({commit}, {yearCupPickup}) {
      return new Promise((resolve, reject) => {
        commit('SET_YEAR_CUP_PICKUP', yearCupPickup)
        resolve()
      })
    },
    setYearCupEntryId({commit}, {yearCupEntryId}) {
      return new Promise((resolve, reject) => {
        commit('SET_YEAR_CUP_ENTRY_ID', yearCupEntryId)
        resolve()
      })
    },
    setYearCupPeriodId({commit}, {yearCupPeriodId}) {
      return new Promise((resolve, reject) => {
        commit('SET_YEAR_CUP_PERIOD_ID', yearCupPeriodId)
        resolve()
      })
    },
    setYearCupCompleteId({commit}, {yearCupCompleteId}) {
      return new Promise((resolve, reject) => {
        commit('SET_YEAR_CUP_COMPLETE_ID', yearCupCompleteId)
        resolve()
      })
    },
    deleteYearCupSelect({commit}) {
      const initYearCupCount = {
        typeAId: "",
        typeASelect: 0,
        typeBId: "",
        typeBSelect: 0,
        isPickUp: false
      }
      return new Promise((resolve, reject) => {
        commit('SET_YEAR_CUP_COUNT', initYearCupCount)
        resolve()
      })
    },
    deleteYearCupPickUpData({commit}) {
      const initPickUpData = {
        email: '',
        name: '',
        kana: '',
        tel: '',
        shop: {
          code: '',
          name: ''
        }
      }
      commit('SET_YEAR_CUP_PICKUP', initPickUpData)
    },
    deleteYearCupAddress({commit}) {
      const initYearCupAddress = {
        email: '',
        name: '',
        kana: '',
        zipcode: '',
        prefectures: '',
        city: '',
        address1: '',
        address2: '',
        tel: '',
      }
      commit('SET_YEAR_CUP_ADDRESS', initYearCupAddress)
    },
    logout({commit}) {
      return new Promise((resolve, reject) => {
        commit('SET_ACCESS_TOKEN', "")
        resolve()
      })
    },
    setRedirectTo({commit}, {redirectTo}) {
      return new Promise((resolve, reject) => {
        commit('SET_REDIRECT_TO', redirectTo)
        resolve()
      })
    },
  },
  modules: {},
})

export default store
